import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TokenService {

  constructor() {
  }

  public save(tokens: any): void {
    localStorage.setItem('token', JSON.stringify(tokens));
  }

  public getAccessTokenExpiresAt(): Date {
    const tokens: any = JSON.parse(localStorage.getItem('token'));
    if (tokens != null && tokens !== '') {
      const accessTokenExpiresAt: string = tokens.accessTokenExpiresAt;

      if (accessTokenExpiresAt != null && accessTokenExpiresAt !== '') {
        return new Date(tokens.accessTokenExpiresAt);
      }
    }

    return new Date('2000-03-25');
  }

  public getRefreshTokenExpiresAt(): Date {
    const tokens: any = JSON.parse(localStorage.getItem('token'));
    if (tokens != null && tokens !== '') {
      const refreshTokenExpiresAt: string = tokens.refreshTokenExpiresAt;


      if (refreshTokenExpiresAt != null && refreshTokenExpiresAt !== '') {
        return new Date(tokens.refreshTokenExpiresAt);
      }
    }

    return new Date('2000-03-25');
  }

  public getRefreshToken(): string {
    const tokens: any = JSON.parse(localStorage.getItem('token'));
    if (tokens != null && tokens !== '') {
      const refreshToken: string = tokens.refreshToken;

      if (refreshToken != null && refreshToken !== '') {
        return tokens.refreshToken;
      }
    }

    return null;
  }

  public getAccessToken(): string {
    const tokens: any = JSON.parse(localStorage.getItem('token'));
    if (tokens != null && tokens !== '') {
      const accessToken: string = tokens.accessToken;

      if (accessToken != null && accessToken !== '') {
        return tokens.accessToken;
      }
    }

    return null;
  }

  public deleteAll(): void {
    localStorage.clear();
  }

}
