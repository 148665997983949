import {Component, Input} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {Router} from '@angular/router';

@Component({
  styleUrls: ['../dialog.common.scss'],
  template: `
    <nb-card class="dialog-card" status="success">
      <nb-card-header>{{ title }}</nb-card-header>
      <nb-card-body>
        {{content}}
      </nb-card-body>
      <nb-card-footer>
        <button nbButton status="success" (click)="dismiss()">Cool Beans!</button>
      </nb-card-footer>
    </nb-card>
  `,
})
export class SuccessDialogComponent {
  @Input() title: string;
  @Input() content: string;
  @Input() redirect: string;

  constructor(private router: Router, protected ref: NbDialogRef<SuccessDialogComponent>) {
  }

  dismiss() {
    if (this.redirect) {
      this.router.navigate([this.redirect]);
    }

    this.ref.close();
  }
}
