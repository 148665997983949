import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {TokenService} from '../token.service';

@Component({
  selector: 'ngx-logout',
  template: '',
})
export class LogoutComponent {

  constructor(private tokenService: TokenService, private router: Router) {
    this.tokenService.deleteAll();
    this.router.navigate(['/auth/login']);
  }
}
