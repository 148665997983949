import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';
import {NbAuthService, NbLoginComponent} from '@nebular/auth';
import {LoginModel} from './login.model';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends NbLoginComponent implements OnInit {
  user: LoginModel = {password: '', username: ''};
  loading: boolean = false;
  error: string = null;

  constructor(private nbAuthService: NbAuthService,
              private changeDetectorRef: ChangeDetectorRef,
              public router: Router,
              private authService: AuthService) {
    super(nbAuthService, {}, changeDetectorRef, router);
  }

  ngOnInit(): void {
  }

  login(): void {
    this.loading = true;

    this.authService.login(this.user).subscribe(tokens => {
      this.loading = false;
      this.router.navigate(['pages/dashboard']);
    }, rejection => {
      this.error = rejection.message;

      setTimeout(() => {
        this.error = null;
      }, 2500);

      this.loading = false;
    });
  }

}
