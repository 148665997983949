import {Injectable} from '@angular/core';

@Injectable()
export class AuthConfiguration {
  private static readonly baseUrl: string = 'https://napi.leicht.io/';

  public static getApiUrl(): string {
    if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
      return 'http://127.0.0.1:2114/';
    }

    return this.baseUrl;
  }
}
