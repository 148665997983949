import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ThemeModule} from '../../@theme/theme.module';
import {NbButtonModule, NbCardModule, NbSpinnerModule} from '@nebular/theme';
import {FormsModule} from '@angular/forms';
import {SuccessDialogComponent} from './success-dialog/success-dialog.component';
import {ErrorDialogComponent} from './error-dialog/error-dialog.component';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';

@NgModule({
  imports: [
    ThemeModule,
    CommonModule,
    NbCardModule,
    NbSpinnerModule,
    FormsModule,
    NbButtonModule,
  ],
  declarations: [
    SuccessDialogComponent,
    ErrorDialogComponent,
    ConfirmDialogComponent,
  ],
  entryComponents: [SuccessDialogComponent, ErrorDialogComponent, ConfirmDialogComponent],
})
export class DialogModule {
}
