import {Component, Input} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';

@Component({
  styleUrls: ['../dialog.common.scss'],
  template: `
    <nb-card class="dialog-card" status="warning">
      <nb-card-header>{{ title }}</nb-card-header>
      <nb-card-body>
        {{content}}
      </nb-card-body>
      <nb-card-footer>
        <button nbButton status="danger" (click)="confirm()">Yes!</button>
        <button nbButton status="danger" (click)="cancel()">No, Cancel!</button>
      </nb-card-footer>
    </nb-card>
  `,
})
export class ConfirmDialogComponent {
  @Input() title: string;
  @Input() content: string;
  @Input() confirmCallback: any;

  constructor(protected ref: NbDialogRef<ConfirmDialogComponent>) {
  }

  confirm(): void {
    this.ref.close();
    this.confirmCallback();
  }

  cancel(): void {
    this.ref.close();
  }
}
