import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthConfiguration} from './auth.configuration';
import {TokenService} from './token.service';
import {AuthService} from './auth.service';
import {Router} from '@angular/router';
import {mergeMap, tap} from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private tokenService: TokenService, private authService: AuthService, private router: Router) {
  }

  private getHeaders(request: HttpRequest<any>): HttpHeaders {
    let authorization: string = '';
    let grantType: string = '';
    if (this.isAuthenticationRequest(request)) {
      authorization = 'Basic YXBwbGljYXRpb246c2VjcmV0';
      grantType = 'password';
    } else if (this.isRevokeRequest(request)) {
      return request.headers;
    } else {
      const accessToken: string = this.tokenService.getAccessToken();

      if (accessToken && accessToken !== '') {
        authorization = 'Bearer ' + accessToken;
      }
    }

    const headers: HttpHeaders = request.headers
      .set('Authorization', authorization)
      .set('grant_type', grantType)
      .set('From', 'Leicht.IO - Admin');

    /*if (this.isMultipartRequest(request)) {
      headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    }*/

    return headers;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isUrlOrProtocolSetInRequest(request)) {
      request = this.getClonedRequestWithApiUrl(request);
    }

    if (this.isAuthenticationRequest(request)) {
      return next.handle(request);
    }

    return this.authService.isAuthenticated().pipe(
      mergeMap(isAuthenticated => {
        if (!isAuthenticated) {
          this.revoke().subscribe(() => {
            return this.getClonedRequestWithApiUrl(request);
          }, rejection => {
            this.router.navigate(['/auth/login']);
          });
        } else {
          return next.handle(request).pipe(
            tap((event: HttpEvent<any>) => {
              if (event instanceof HttpResponse) {
                // do stuff with response if you want
                return request;
              }
            }, (err: any) => {
              if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                  this.revoke().subscribe(() => {
                    return this.getClonedRequestWithApiUrl(request);
                  }, rejection => {
                    this.router.navigate(['/auth/login']);
                  });
                } else {

                }
              }
            }));
        }
      }));
  }

  private revoke() {
    return this.authService.revokeToken().map(tap((tokens) => {
      this.tokenService.save(tokens);
      return tokens;
    }));
  }

  private getClonedRequestWithApiUrl(request: HttpRequest<any>): HttpRequest<any> {
    const headers = this.getHeaders(request);
    const cloneReq = request.clone({headers});

    return cloneReq.clone({
      url: AuthConfiguration.getApiUrl() + request.url,
    });
  }

  private isUrlOrProtocolSetInRequest(request: HttpRequest<any>): boolean {
    return !(request.url.indexOf(AuthConfiguration.getApiUrl()) === -1);
  }

  private isAuthenticationRequest(request: HttpRequest<any>): boolean {
    return request.url.indexOf('auth/authenticate') > -1;
  }

  private isRevokeRequest(request: HttpRequest<any>): boolean {
    return request.url.indexOf('auth/revoke') > -1;
  }

  private isMultipartRequest(request: HttpRequest<any>): boolean {
    return request.headers.get('x-is-multipart') === null || request.headers.get('x-is-multipart') !== 'true';
  }
}
