import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ThemeModule} from '../../@theme/theme.module';
import {
  NbAlertModule, NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbIconModule,
  NbInputModule, NbLayoutModule,
  NbSpinnerModule,
} from '@nebular/theme';
import {FormsModule} from '@angular/forms';
import {LoginComponent} from './login/login.component';
import {LogoutComponent} from './logout/logout.component';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [
    ThemeModule,
    CommonModule,
    NbCardModule,
    NbSpinnerModule,
    FormsModule,
    NbAlertModule,
    NbInputModule,
    NbCheckboxModule,
    RouterModule,
    NbIconModule,
    NbButtonModule,
    NbAlertModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbIconModule,
    NbInputModule,
    NbLayoutModule,
  ],
  declarations: [
    LoginComponent,
    LogoutComponent,
  ],
})
export class AuthModule {
}
