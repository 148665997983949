import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TokenService} from './token.service';
import 'rxjs/add/operator/map';
import {LoginModel} from './login/login.model';
import {Router} from '@angular/router';
import set = Reflect.set;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private httpClient: HttpClient, private tokenService: TokenService, private router: Router) {
  }

  public login(model: LoginModel): Observable<any> {
    const options: any = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded'),
    };

    const body: URLSearchParams = new URLSearchParams();
    body.set('username', model.username);
    body.set('password', model.password);
    body.set('grant_type', 'password');

    return this.httpClient.post('auth/authenticate', body.toString(), options)
      .map(tokens => {
        this.tokenService.save(tokens);
        return tokens;
      });
  }

  public revokeToken(): Observable<any> {
    const isRefreshTokenExpired: boolean = this.isRefreshTokenExpired();

    if (isRefreshTokenExpired) {
      this.router.navigate(['/auth/logout']);
    } else {
      const options: any = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
          .set('Authorization', 'Basic YXBwbGljYXRpb246c2VjcmV0'),
      };

      const body: URLSearchParams = new URLSearchParams();
      body.set('refresh_token', this.tokenService.getRefreshToken());
      body.set('grant_type', 'refresh_token');

      return this.httpClient.post('auth/revoke', body.toString(), options);
    }
  }

  public isRefreshTokenExpired(): boolean {
    const now: Date = new Date();
    const refreshTokenExpiresAt: Date = this.tokenService.getRefreshTokenExpiresAt();

    return !(refreshTokenExpiresAt != null && now < refreshTokenExpiresAt);
  }

  public isAuthenticated(): Observable<boolean> {
    const now: Date = new Date();
    const accessTokenExpiresAt: Date = this.tokenService.getAccessTokenExpiresAt();

    return of(accessTokenExpiresAt != null && now < accessTokenExpiresAt);
  }
}
